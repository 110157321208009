<template>
  <div>
    <el-row style="margin-bottom: 20px" :gutter="24">
      <el-col :span="3" style="margin-right: 15px;">
        <el-select
          v-model="ruleForm.user_type"
          clearable
          placeholder="请选择单位类型"
        >
          <el-option
            v-for="item in provinceids"
            :key="item.id"
            :label="item.name"
            :value="item.id"
            @click.native="goto_Roles(item.id)"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px;" v-if="ruleForm.user_type">
        <el-select
          v-model="ruleForm.patrol_company_id"
          clearable
          placeholder="请选择单位名称"
        >
          <el-option
            v-for="item in prov_xuncha"
            :key="item.id"
            :label="item.company_name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px;">
        <el-select
          v-model="ruleForm.type"
          placeholder="请选择巡查类型"
          clearable
          @change="goto_options"
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3" style="margin-right: 15px;" v-if="ruleForm.type">
        <el-select
          v-model="ruleForm.inspection_category_id"
          clearable
          placeholder="请选择巡查项目类型"
        >
          <el-option
            v-for="item in category"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <!-- <el-col :span="3" style="margin-right: 15px;">
				<el-select v-model="ruleForm.question" clearable placeholder="请选择是否发现问题">
					<el-option v-for="item in provinceid" :key="item.key" :label="item.val" :value="item.key">
					</el-option>
				</el-select>
			</el-col> -->
      <el-col :span="3" style="margin-right: 15px;">
        <el-select
          v-model="ruleForm.status"
          clearable
          placeholder="请选择整改确认情况"
        >
          <el-option
            v-for="item in todolist"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="5" style="margin-right: 15px;">
        <el-date-picker
          clearable
          v-model="daylist"
          type="daterange"
          value-format="yyyyMMdd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="init()">查询</el-button>
        <!-- <el-button type="" @click="dialogVisible = true">新增巡查人员</el-button> -->
      </el-col>
    </el-row>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="id" label="编号"> </el-table-column>
      <el-table-column prop="patrol_role_name" label="单位类型">
      </el-table-column>
      <el-table-column prop="patrols_company_name" label="单位名称">
      </el-table-column>

      <el-table-column prop="patrols_name" label="巡查人"> </el-table-column>
      <el-table-column prop="type" label="巡查类型">
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">市场自查</span>
          <span v-if="scope.row.type === 2">监管巡查</span>
        </template>
      </el-table-column>
      <el-table-column prop="categorys_name" label="巡查项目">
      </el-table-column>
      <!-- <el-table-column prop="status" label="是否发现问题">
				<template slot-scope="scope">
					<span v-if="scope.row.type===1">是</span>
					<span v-if="scope.row.type===2">否</span>
				</template>
			</el-table-column> -->
      <el-table-column prop="status" label="开办方确认情况">
        <template slot-scope="scope">
          <span v-if="scope.row.status === 2"> 已巡查待确认</span>
          <span v-if="scope.row.status === 3"> 已巡查待整改</span>
          <span v-if="scope.row.status === 4"> 已巡查已确认</span>
          <span v-if="scope.row.status === 5"> 已巡查已整改</span>
        </template>
      </el-table-column>
      <el-table-column prop="sponsor_picture" label="签字情况">
        <template
          slot-scope="scope"
          style="display: flex; justify-content: center;"
        >
          <img
            v-if="scope.row.sponsor_picture"
            :src="scope.row.sponsor_picture"
            style="width: 60px;heigh: 60px;"
          />
          <img
            :src="scope.row.supervise_picture"
            style="width: 60px;height: 60px;"
          />
        </template>
      </el-table-column>
      <el-table-column prop="market_name" label="巡查地点"> </el-table-column>
      <el-table-column prop="created_at" label="巡查时间"> </el-table-column>
      <el-table-column prop="address" label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="success" @click="query(scope.row)">查看</el-button>
          <el-button type="danger" @click="delete_text(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div
      style="display: flex;justify-content:
		center;align-items: center;margin-top: 15px"
    >
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="ruleForm.page"
        :page-sizes="[10, 20, 30]"
        :page-size="ruleForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="ruleForm.total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="查看明细"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      conten
    >
      <el-table :data="tadoDatas" border style="width: 100%">
        <el-table-column label="序号" type="index" width="50">
        </el-table-column>
        <el-table-column prop="question_picture" label="问题图片">
          <template slot-scope="scope">
            <img
              :src="scope.row.question_picture"
              style="width: 60px;heigh: 60px;"
            />
          </template>
        </el-table-column>
        <el-table-column prop="content" label="问题描述"> </el-table-column>
        <el-table-column prop="question" label="发现问题">
          <template slot-scope="scope">
            <span v-if="scope.row.question === 0">否</span>
            <span v-if="scope.row.question === 1">是</span>
          </template>
        </el-table-column>
        <el-table-column prop="question_explain" label="问题情况说明">
        </el-table-column>
        <el-table-column prop="reply_explain" label="整改情况说明">
        </el-table-column>
        <el-table-column prop="reply_picture" label="整改情况图片">
          <template slot-scope="scope">
            <img
              :src="scope.row.reply_picture"
              style="width: 60px;heigh: 60px;"
            />
          </template>
        </el-table-column>
        <el-table-column prop="rectification" label="整改状态">
          <template slot-scope="scope">
            <span v-if="scope.row.rectification === 2">已整改</span>
            <span v-if="scope.row.rectification === 1">整改中</span>
            <span v-if="scope.row.rectification === 3">未整改</span>
          </template>
        </el-table-column>

        <el-table-column prop="created_at" label="巡查时间"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibles"
      width="30%"
      center
      :before-close="handleClose"
    >
      <span
        style="text-align: center;
    display: block;"
        >确定刪除该条数据?</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibles = false">取 消</el-button>
        <el-button type="primary" @click="delete_Reco">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  Inspec_tionRecords,
  getRoles,
  get_InRecords,
  get_PatrolCompany,
  get_InspectionCategorys,
  delete_Records
} from '@/api/manage'

export default {
  name: 'list',
  data () {
    return {
      dialogVisibles: false,
      options: [
        {
          id: 1,
          name: '市场自查'
        },
        {
          id: 2,
          name: '监管人员巡查'
        }
      ],
      tadoDatas: [],
      tadoData: [],
      provinceids: [],
      category: [],
      provinceid: [
        {
          key: 0,
          val: '是'
        },
        {
          key: 1,
          val: '否'
        }
      ],
      todolist: [
        {
          id: 2,
          name: '已巡查待确认'
        },
        {
          id: 3,
          name: '已巡查待整改'
        },
        {
          id: 4,
          name: '已巡查已确认'
        },
        {
          id: 5,
          name: '已巡查已整改'
        }
      ],
      // 条件筛选字段
      ruleForm: {
        user_type: '',
        inspection_category_id: '',
        patrol_company_id: '',
        status: '',
        day: '',
        question: '',
        page: 1,
        limit: 10,
        total: 0
      },
      tableData: [],
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      dialogVisible: false,
      daylist: [],
      prov_xuncha: [],
      ids: ''
    }
  },
  created () {
    this.init()
    this.Get_Roles()
  },
  methods: {
    // 初始化数据接口
    init () {
      ;(this.ruleForm.day = this.daylist.toString()),
        Inspec_tionRecords(this.ruleForm).then(res => {
          this.tableData = res.data.data
          this.ruleForm.total = res.data.total
        })
    },
    // 获取单位类型
    Get_Roles () {
      getRoles().then(res => {
        this.provinceids = res.data
      })
    },

    goto_Roles (value) {
      this.ruleForm.patrol_company_id = ''
      get_PatrolCompany({
        user_type: this.ruleForm.user_type
      }).then(res => {
        this.prov_xuncha = res.data
      })
    },
    goto_options (valeu) {
      console.log(valeu)
      this.ruleForm.inspection_category_id = ''
      get_InspectionCategorys({
        type: this.ruleForm.type
      }).then(res => {
        this.category = res.data
      })
    },
    delete_text (num) {
      this.dialogVisibles = true
      this.ids = num
      console.log(num)
    },
    delete_Reco () {
      delete_Records(this.ids).then(res => {
        this.dialogVisibles = false
        this.$message.success(res.data)
        this.init()
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    beforeAvatarUpload (file) {
      const isJPG1 = file.type === 'image/png'
      const isJPG2 = file.type === 'image/jpeg'
      const isJPG3 = file.type === 'image/bmp'
      const isJPG4 = file.type === 'image/gif'
      const img = [isJPG1, isJPG2, isJPG3, isJPG4]
      const some = img.some(item => {
        return item
      })
      if (!some) {
        this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
      }
      return some
    },
    // 获取真实的路径
    uploadSectionFile2 (param) {
      // this.ruleForm.device_positive_pic = URL.createObjectURL(param.file)
      upload(param)
        .then(res => {
          this.ruleForm.device_positive_pic = res
        })
        .catch(() => {
          this.$message.error('上传的图片只能是png,jpeg,bmp,gif格式!')
        })
    },
    // 分页功能
    handleSizeChange (val) {
      this.ruleForm.page = 1
      this.ruleForm.limit = val
      this.init()
    },
    handleCurrentChange (val) {
      this.ruleForm.page = val
      this.init()
    },
    // 查看明细
    query (item) {
      this.dialogVisible = true
      get_InRecords(item.id).then(res => {
        this.tadoDatas = res.data
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-icon-plus {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
</style>
